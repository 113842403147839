import axios from 'axios';
import config from './config.js';

const ENDPOINTS = {
    CITIES: "cities",
    STRUCTURES: "structures",
    TIPOLOGIA_DI_PROGRAMMA: "tipologiadiprogramma",
    ALTERNATIVE_SERVICES: "alternative-services",
    EXTRA_SERVICES: "extra-services"
}

const MAP_ALTERNATIVE_LABEL_ENDPOINT = {
    "cittapartenza": ENDPOINTS.CITIES,
    "struttura": ENDPOINTS.STRUCTURES,
    "tipologiadiprogramma": ENDPOINTS.TIPOLOGIA_DI_PROGRAMMA,
    "contributoinps": ENDPOINTS.ALTERNATIVE_SERVICES,
    "bonus": ENDPOINTS.ALTERNATIVE_SERVICES,
    "scontovoucher": ENDPOINTS.ALTERNATIVE_SERVICES,
    "scontogiftcard": ENDPOINTS.ALTERNATIVE_SERVICES,
    "scontocodicepromo": ENDPOINTS.ALTERNATIVE_SERVICES,
    "*": ENDPOINTS.EXTRA_SERVICES,
}

/* productDivision: form[value] */
const MAP_PRODUCT_DIVISION_FORM = {
    1: "",          /*La mia estate soggiorni*/
    // 2: "",          /*Fuori Classe*/
    3: "",          /*Vacanze studio*/
    4: "docenti",   /*Gruppi docenti*/
    // 5: "",          /*StudyBooking*/
    // 6: "",          /*Corsi di lingua*/
    // 7: "",          /*Alternanza scuola lavoro*/
    8: "hsp",       /*HSP*/
    // 9: "",          /*Corsi di lingua per docenti*/
    // 10: "",         /*Ministay*/
    11: "ada",      /*Anni d'Argento Soggiorni*/
}

export const PRODUCT_DIVISIONS_LIST = {
    1: "La mia estate soggiorni",
    2: "Fuori Classe",
    3: "Vacanze studio",
    4: "Gruppi docenti",
    5: "StudyBooking",
    6: "Corsi di lingua",
    7: "Alternanza scuola lavoro",
    8: "HSP",
    9: "Corsi di lingua per docenti",
    10: "Ministay",
    11: "Anni d'Argento Soggiorni",
}


const http = axios.create({
    baseURL: config.apiUrl,
});

http.interceptors.request.use(function (config) {
    const currentToken = localStorage.getItem('TOKEN');

    if (currentToken) {
        config.headers['Authorization'] = `Bearer ${currentToken}`;
    }

    return config;
});

export const login = async ({username, password}) => {
    // return {
    //   token:
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1Nzc0MzU5NjAsImV4cCI6MTU3ODA0MDc2MCwicm9sZXMiOlsiUk9MRV9NRU1CRVIiXSwidXNlcm5hbWUiOiJtYXR0ZW8ubWFuY2hpQGdtYWlsLmNvbSJ9.hYmG4yZ-p-0HzwcSeFmDoOpcos72XgOKSASUnwxuKvy1HmyusonOcd8HGlQ2ijNowsXmX869pE6JzACZ72CZ9CDCmUpHnZ_mWCzEPwnvbQpPdC1SKCQtiApkUWYQGeqNmTDKbNJzOsgVSXZsAB2nWsxey61oF8MxkkJZ-6DjigC1Fh-4tXoHHAb-K4m4psNEL7orxCh0_uRvlwo_BAgjot525oQtsP0L5HGwihsc94SORtV9AEfa9DeABAG7WwKjHCndRGGHN_y8Cl6uwHkcy_E57IJ9VEYJY9Q6iDXFOOqiJaGYmgPH5H_9y49aMXpnX5OaB26qQJAgjxSmMDydlP-qvqkFC9zPhT519Vr7_TCenM3_pJgchl3yrhYloJAYMMhibzV83I3bDvCUNBqLox4EM0InVPWSsJRHHjgtzvfHgJm4GQ4-CrwtTobjs5FOiQR-Q_Nhuv42Oy6VyoGCNcET79ref_x2FSxvcEvKyUof1wTZ3Y1SjUQ4i_T0tVTALBo8xhxzVzKkObEFSt-KvGkZzNQ0THE8OdY1qh4M7D-2aTMg3K3pHB464kiODfl7UmWDPKyH07Huj-lxC1fL5aJg055CiocR2l0C14GPrewVL--Qphgc3Ov8G9h1NQU64htRUHQGoWaYvo0kYulPj17j5bdGpFsgn_aZcpoPeCo',
    // };

    const response = await http.post('/member/login', {
        username,
        password,
    });

    return response.data;
};

export const passwordRecovery = async data => {
    const response = await http.post('/member/password-recovery', data);

    return response.data;
};

export const usernameRecovery = async data => {
    const response = await http.post('/member/username-recovery', data);

    return response.data;
};

export const fetchUser = async () => {
    const response = await http.get('/member/me');

    const data = {...response.data};

    if (data.points === null) {
        data.points = 0;
    }

    if (typeof data.firstName !== 'string') {
        data.firstName = '';
    }

    if (typeof data.lastName !== 'string') {
        data.lastName = '';
    }

    const clusterCode = data.clusterCode?.toLowerCase();

    return {...data, clusterCode};
};

export const updateTourCompletedStatus = async () => {
    const response = await http.put('/member/me/tour-completed');
    return response.data;
};

export const updateMemberInfo = async data => {
    const {nationality, ...otherData} = data;

    await http.put('/member/me', otherData);
    return otherData;
};

export const updateMemberPassword = async data => {
    const response = await http.put('/member/me/password-change', data);
    return response.data;
};

export const updateMemberUsername = async data => {
    const response = await http.put('/member/me/username-change', data);
    return response.data;
};

export const deleteAccount = async data => {
    const response = await http.put('/member/me/delete-account');
    return response.data;
};

export const registerMember = async data => {
    const {nationality, repeatPassword, ...otherData} = data;

    const response = await http.post('/member/register', otherData);
    return response.data;
};

export const fetchPractices = async () => {
    const response = await http.get(`/member/practice`);

    const data = response.data.map(practice => ({
        ...practice,
        readableStatus: practice.readableStatus.toLowerCase(),
    }));

    return data;
};

export const fetchDynamicSections = async () => {
    const response = await http.get(`/member/sections`);

    return response.data;
};

export const fetchPractice = async id => {
    const response = await http.get(`/member/practice/${id}`);
    return response.data;
};

export const fetchPracticeInvoiceDocuments = async practiceCode => {
    const response = await http.get(`/member/practice/zucchetti/listadocumenti/${practiceCode}`)
    return response.data
};

export const fetchPracticesDocuments = async () => {
    const response = await http.get(`/member/practice/documents`);

    const data = response.data.map(practice => ({
        ...practice,
        readableStatus: practice.readableStatus.toLowerCase(),
    }));

    return data;
};

export const fetchVouchers = async () => {
    const response = await http.get(`/member/voucher`);
    return response.data;
};

// TODO: finalize get documents types from backend
export const uploadPraticeDocumentFile = async data => {
    const response = await http.post(`/member/practice/uploadDocument`, data);
    return response.data;
};

// export const getDocumentTypologies = async () => {
//   //GET api/member/practice/documentTypology
// };

export const fetchDocumentTypologies = async () => {
    const response = await http.get(`/member/practice/documentTypology`);

    return response.data;
};

export const fetchPracticesPayments = async () => {
    const response = await http.get(`/member/practice/payments`);

    const data = response.data.map(practice => ({
        ...practice,
        readableStatus: practice.readableStatus.toLowerCase(),
    }));

    return data;
};

export const fetchDocuments = async () => {
    const response = await http.get(`/member/document`);
    return response.data;
};

export const fetchDocument = async id => {
    const response = await http.get(`/member/document/${id}`);
    return response.data;
};

export const fetchCities = async () => {
    const response = await http.get(`/member/geo/comuni`);
    return response.data;
};

export const calculateFiscalCode = async data => {
    const response = await http.post(`/member/fiscalcode-calculate`, data);
    return response.data.fiscalCode;
};

export const fetchMemberRelationships = async () => {
    const response = await http.get(`/member/me/relationships`);
    return response.data;
};

export const updateMemberRelationships = async (id, data) => {
    const {nationality, type, ...member} = data;

    const response = await http.put(`/member/me/relationships/${id}`, {
        type,
        member,
    });
    return response.data;
};

export const fetchIntercomUserHash = async () => {
    const response = await http.get(`/member/me/intercom_chat_hash`);
    return response.data;
};

export const updatePracticeDetails = async (id, data) => {
    const response = await http.put(`/member/practice/${id}`, data);
    return response.data;
};

//paypal payments
export const getPaypalClientId = async data => {
    const response = await http.post('/payments/paypal/api', data);
    return response.data;
};

export const finalizePaypalOrder = async data => {
    const response = await http.post('/payments/paypal/finalize', data);
    return response.data;
};

export const markPaymentError = async data => {
    const response = await http.post('/payments/paypal/markAsError', data);
    return response.data;
};

export const startSignProcedure = async (documentId) => {
    const response = await http.get(`/member/practice/document/${documentId}/signature/data`);
    return response.data;
}

export const endSignProcedure = async (documentId, procedureId) => {
    const response = await http.post(`/member/practice/document/signature/result`, {
        document_id: documentId,
        procedure_id: procedureId,
    });
    return response.data;
}

export const downloadSignedDocument = async (documentId) => {
    const response = await http.get(`/member/practice/document/${documentId}/signature/download`, {
        responseType: 'blob'
    });
    return response.data;
}

export const editServices = async (data) => {
    const response = await http.post(`/member/practice/editServices`, data);
    return response.data;
}

export const updatePreview = async (data) => {
    const response = await http.post(`/member/practice/editPreview`, data);
    return response.data;
}
export const editPackage = async (data) => {
    const response = await http.post(`/member/practice/editPackage`, data);
    return response.data;
}

export const upsellPractice = async (data) => {
    const response = await http.post(`/member/practice/upselling`, data);
    return response.data;
}


export const getDestinations = async (division) => {
    const {data} = await http.get(`form/destinations/${division}`)
    return data
}

export const getPrograms = async (destination, division) => {
    const {data} = await http.post('form/destinations/packages', {
        destination,
        division,
    })
    return data
}

export const getPackages = async (webgroup, division) => {
    const {data} = await http.post('form/turns', {webgroup, division})
    return data
}

export const getParams = async (productDivisionId, keys) => {
    const {data} = await http.post('form/flags', {
        productDivision: productDivisionId,
        confKeys: keys,
    })
    return data
}

export async function updatePaymentData(practiceGUID, partecipazione, tipo) {
    const body = {
        practice: practiceGUID,
        partecipazione: partecipazione,
        tipo: tipo,
    }
    const {data} = await http.post('payments/updatePaymentData', body)
    return data
}


export const getDepartureCities = async (packageId, division) => {
    const {data} = await http.post('form/package/cities', {
        package: packageId,
        division: division,
    })
    return data
}


export const fetchAlternativeServicesByAlternativeLabel = async (alternativeLabel, productDivision, packageId, divisionId) => {
    //Attenzione perché ritorna sempre qualcosa, nonostante l'alternativeLabel in teoria è inesistente e quindi chiama extra-services
    const endpoint = MAP_ALTERNATIVE_LABEL_ENDPOINT[alternativeLabel] ?? MAP_ALTERNATIVE_LABEL_ENDPOINT['*'];
    const baseUrl = `/form${MAP_PRODUCT_DIVISION_FORM[productDivision]}/package/${endpoint}`;

    const response = await http.post(baseUrl, _buildBody(endpoint, packageId, divisionId, alternativeLabel));
    let val;
    const obtainOnlyExtraServices = (endpoint === ENDPOINTS.EXTRA_SERVICES);
    if (obtainOnlyExtraServices) {
        //Prelevo soltanto l'extra che è uguale all'alternative label che sto ricevendo
        if (response.data && !Array.isArray(response.data) && alternativeLabel in response.data) {
            val = response.data[alternativeLabel];
            // filtra via non modificabili in extra-services
            val.extraservices = val.extraservices.filter(service => service.editableInClubArea === true);
            if (val.extraservices.length === 0) val = null
        }
    } else {
        // filtra via non modificabil in alternative-services
        val = response.data.filter(service => service.editableInClubArea === true);
    }
    return obtainOnlyExtraServices ? {val, alternativeLabel} : val;
};

const _buildBody = (endpoint, packageId, divisionId, alternative) => {
    let result = {
        package: packageId,
        division: divisionId,
    };
    switch (endpoint) {
        case ENDPOINTS.CITIES:
        case ENDPOINTS.STRUCTURES:
        case ENDPOINTS.TIPOLOGIA_DI_PROGRAMMA:
            break;
        case ENDPOINTS.ALTERNATIVE_SERVICES:
        case ENDPOINTS.EXTRA_SERVICES:
            result = {
                ...result,
                alternative,
            }
            break;
    }
    return result;
}
